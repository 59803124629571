@font-face {
    font-family: "GeoSansLight";
    src: local(GeoSansLight), url("/src/assets/fonts/geo_sans_light/GeosansLight.ttf") format("truetype");
}

@font-face {
    font-family: "WindSong";
    src: local('WindSong'), url("/src/assets/fonts/WindSong/WindSong-Medium.ttf") format("truetype");
}

body {
    --bs-body-font-family: "GeoSansLight";
    --bs-body-color: #879DB0;
    padding-top: 60px;
    padding-bottom: 20px;
    /*overflow: scroll;*/
}


.windSong {
    font-family: "WindSong", sans-serif !important;
    color: #879DB0 !important;
}
.fullPage {
    min-width: 100%;
    min-height: 100%;
    width: 90%;
    height: 90%;
    position: fixed;
    overflow: auto;
}


.addSpaceOnTop {
    margin-top: calc(100% - 70px);
}

.fadedBackground {
    background-color: rgba(255, 255, 255, 0.85);
}
/*Floral Background*/
/* X-Small devices (portrait phones, less than 576px)*/
/*// No media query for `xs` since this is the default in Bootstrap*/
@media (max-width: 575.98px) {
    .fullPagePositionRelative {
        min-width: 100%;
        min-height: 100%;
        max-width: 98%;
        width: 100%;
        height: 100%;
        margin-bottom: 70px;
        /*position: fixed;*/
        overflow: scroll;
    }
}

/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
    .floralBackground {
        background: url("/src/assets/images/floral-vertical.png") no-repeat left center fixed;
        background-size: contain;
    }
    .fullPagePositionRelative {
        min-width: 100%;
        min-height: 100%;
        max-width: 98%;
        width: 100%;
        height: 100%;
        margin-bottom: 70px;
        overflow: scroll;
    }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
    .floralBackground {
        background: url("/src/assets/images/floral-vertical.png") no-repeat left center fixed;
        background-size: contain;
    }
    .fullPagePositionRelative {
        min-width: 100%;
        min-height: 100%;
        max-width: 98%;
        width: 100%;
        height: 100%;
        margin-bottom: 70px;
        overflow: scroll;
    }
}
/*special query for ipad*/
@media (min-height: 600px) and (max-width: 992px){
    .floralBackground {
        background: url("/src/assets/images/floral-horizontal.png") no-repeat top fixed;
        background-size: contain;
    }
    .fullPagePositionRelative {
        min-width: 100%;
        min-height: 100%;
        max-width: 98%;
        width: 100%;
        height: 100%;
        margin-bottom: 70px;
        position: fixed;
        overflow: scroll;
    }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
    .floralBackground {
        background: url("/src/assets/images/floral-vertical.png") no-repeat left center fixed;
        background-size: contain;
    }
    .fullPagePositionRelative {
        min-width: 100%;
        min-height: 100%;
        max-width: 98%;
        width: 100%;
        height: 100%;
        margin-bottom: 70px;
        position: fixed;
        overflow: scroll;
    }
}

/*// X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) and (max-width: 1399.98px) {
    .floralBackground {
        background: url("/src/assets/images/floral-vertical.png") no-repeat left center fixed;
        background-size: contain;
    }
    .fullPagePositionRelative {
        min-width: 100%;
        min-height: 100%;
        max-width: 98%;
        width: 100%;
        height: 100%;
        margin-bottom: 70px;
        position: fixed;
        overflow: scroll;
    }
}

/*// XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {
    .floralBackground {
        background: url("/src/assets/images/floral-vertical.png") no-repeat left center fixed;
        background-size: contain;
    }
    .fullPagePositionRelative {
        min-width: 100%;
        min-height: 100%;
        max-width: 98%;
        width: 100%;
        height: 100%;
        margin-bottom: 70px;
        position: fixed;
        overflow: scroll;
    }
}



