
/*Landing Background*/
/* X-Small devices (portrait phones, less than 576px)*/
/*// No media query for `xs` since this is the default in Bootstrap*/
@media (max-width: 575.98px) {
    .landingBackground {
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("/src/assets/images/_SAB0408.jpg");
        background-attachment: unset;
        background-size: cover;
    }
}

/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
    .landingBackground {
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("/src/assets/images/_SAB0408.jpg");
        background-attachment: unset;
        background-size: cover;
    }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
    .landingBackground {
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("/src/assets/images/_SAB0408.jpg");
        background-attachment: unset;
        background-size: cover;
    }
}
/*special query for ipad*/
@media (min-height: 600px) and (max-width: 992px){
    .landingBackground {
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("/src/assets/images/_SAB0408.jpg");
        background-attachment: unset;
        background-size: cover;
    }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
    .landingBackground {
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("/src/assets/images/_SAB0408.jpg");
        background-attachment: unset;
        background-size: cover;
    }
}

/*// X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) and (max-width: 1399.98px) {
    .landingBackground {
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("/src/assets/images/_SAB0408.jpg");
        background-attachment: unset;
        background-size: cover;
    }
}

/*// XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {
    .landingBackground {
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("/src/assets/images/_SAB0408.jpg");
        background-attachment: unset;
        background-size: cover;
    }
}
